import { useEffect } from "react"
import { Icon, NonIdealState } from "@blueprintjs/core"
import styled from "@emotion/styled"

import FormattedNotificationList from "./FormattedNotificationList"
import { useNotifications } from "features/NotificationsProvider"

const UnreadContainer = styled.div`
  margin-bottom: 58px;
`

const NoNotifications = styled(NonIdealState)`
  .title {
    color: white;
  }
`

/**
 * Formats notifications into two lists, one list of unread or unviewed notifications and one of viewed.
 */
export const NotificationsFormatter = () => {
  const { notifications, markUnreadAsRead } = useNotifications()

  useEffect(() => {
    return () => {
      markUnreadAsRead()
    }
  }, [])

  const viewedNotifications = notifications.filter(
    (notification) => notification.read
  )

  const unviewedNotifications = notifications.filter(
    (notification) => !notification.read
  )

  if (notifications?.length > 0) {
    return (
      <div className="flex items-center flex-col">
        {unviewedNotifications.length > 0 && (
          <UnreadContainer>
            <FormattedNotificationList
              notifications={unviewedNotifications}
              title="New"
            />
          </UnreadContainer>
        )}

        <FormattedNotificationList
          viewed
          title="Viewed"
          limit={8}
          notifications={viewedNotifications}
        />
      </div>
    )
  }

  return (
    <NoNotifications
      icon={<Icon icon="inbox" color="white" size={32} />}
      title={<h3 className="title">No Notifications</h3>}
    />
  )
}
