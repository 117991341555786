import * as React from "react"

export default function useOnSelectWindowMessage(
  onClose = () => null,
  onSelect = () => null
) {
  React.useEffect(() => {
    function onIManageMessage(evt) {
      if (evt.data.type === "cancel") return onClose()

      if (evt?.data?.type === "select") {
        onSelect(evt.data.data.output_data.loc)
      }
    }

    window.addEventListener("message", onIManageMessage, false)

    return () => window.removeEventListener("message", onIManageMessage, false)
  }, [onClose, onSelect])
}
