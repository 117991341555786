import getFilenameFromUrl from "lib/filename-from-url"

export default async function downloadFile(
  url,
  onProgress = () => {},
  { fetcher = fetch } = {}
) {
  let response = await fetcher(url)

  if (!response.ok) {
    throw {
      message: "FILE_DOWNLOAD_REQUEST_ERROR: " + response.statusText,
      response,
    }
  }

  let bodyReader = response.body.getReader()
  let contentLength = Number(response.headers.get("Content-Length"))

  let receivedLength = 0
  let chunks = []
  let isChunking = true

  while (isChunking) {
    let { done, value } = await bodyReader.read()
    if (done) {
      isChunking = false
      break
    }
    chunks.push(value)
    receivedLength += value.length
    onProgress(receivedLength / contentLength)
  }

  let fileChunks = new Uint8Array(receivedLength)
  let position = 0
  for (let chunk of chunks) {
    fileChunks.set(chunk, position)
    position += chunk.length
  }

  return new File([fileChunks], decodeURIComponent(getFilenameFromUrl(url)))
}
