import { Text } from "@blueprintjs/core"

import { formatDistanceStrict } from "date-fns"

import { Notification } from "./"
import NotificationAction from "./NotificationAction"
import { determineIcon, determineSubtitle } from "./utils"

/**
 * Formats and displays notifications appropriately based on their contents/properties.
 */

type FormattedNotificationListItemProps = {
  notification?: any
  read?: boolean
  type?: string
  extraData?: Record<string, any>
  downloadUrls?: Record<string, any>
  createdAt?: string
}

const FormattedNotificationListItem = (notification) => {
  const {
    read: viewed,
    type,
    extraData,
    createdAt,
    title,
  } = notification as FormattedNotificationListItemProps

  const { target: { name, transaction_name } = {} } = extraData
  const subtitle = determineSubtitle(type, extraData)
  return (
    <Notification icon={determineIcon(type)} viewed={viewed}>
      <Notification.Body>
        <Notification.BodyTitle className="mb-[2px]">
          {name || transaction_name || title}
        </Notification.BodyTitle>
        <Notification.BodySubtitle>
          <Text className="inline" title={subtitle}>
            {subtitle}
          </Text>
          <span className="mx-1 inline-block h-1 w-1 rounded-full bg-current align-middle" />
          <span className="flex-shrink-0">
            {`${formatDistanceStrict(new Date(createdAt), new Date())} ago`}
          </span>
        </Notification.BodySubtitle>
      </Notification.Body>
      <Notification.Action viewed={viewed}>
        <NotificationAction {...notification} eventType={type} />
      </Notification.Action>
    </Notification>
  )
}

export default FormattedNotificationListItem
