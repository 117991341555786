import { ReactNode } from "react"
import SVG from "./iconSVG"

type IconProps = {
  icon?: ReactNode
  color?: string
  width?: string
  height?: string
}

const Icon = ({
  icon = "",
  color = "",
  width = "20px",
  height = "20px",
}: IconProps) => {
  if (typeof icon === "string" || icon instanceof String) {
    return (
      <SVG color={color} width={width} height={height}>
        <path d={icon} />
      </SVG>
    )
  }

  return (
    <SVG color={color} width={width} height={height}>
      {icon}
    </SVG>
  )
}

export default Icon
