import * as React from "react"

import { ErrorClient as ErrorReporter } from "app/config/services/error-reporting"

import * as api from "helpers/api"

export default function useSaveToDMSLocation(fileData) {
  let [status, setStatus] = React.useState("INITIAL")

  function onSelect(locationData) {
    async function send() {
      setStatus("SAVING")
      let apiData = {
        customerId: fileData.customerId,
        folderId: locationData.id,
        libraryId: locationData.database,
        fileUrl: fileData.fileUrl,
      }

      try {
        await api.uploadIMangeFile(apiData)
        setStatus("SUCCESS")
      } catch (e) {
        ErrorReporter.notify(
          new Error({ ...e, message: "Error uploading to iManage" })
        )
        setStatus("ERROR")
      }
    }

    send()
  }

  return { status, onSelect }
}
