import { v4 as uuid } from "uuid"
import { unzip, setOptions } from "unzipit"
import { ErrorClient as Tracker } from "app/config/services/error-reporting"

setOptions({ workerURL: "/workers/unzipit-worker.min.js" })

export default async function unZipFolder(
  file,
  { unzipper = unzip, genUUID = uuid, tracker = Tracker } = {}
) {
  let startMS = performance.now()
  let { entries: items } = await unzipper(file)
  let endMS = performance.now()

  tracker.addAction("unzip-folder", {
    duration: endMS - startMS,
    size: Object.entries(items).length,
  })

  let zipFilesData = await Promise.all(
    Object.entries(items).map(async ([name, item]) => {
      let blob = await item.blob()

      return { id: genUUID(), name, item, blob }
    })
  )

  return zipFilesData.reduce((acc, fileData) => {
    acc[fileData.id] = fileData
    return acc
  }, {})
}
