import { ExternalHttp as http } from "lib/http"

export default async function createFolder(destinationId, folderName) {
  // allow skipping folder creation by passing in empty string and returning the destinationId
  if (folderName === "") {
    return { id: destinationId }
  }
  let result = await http.post(
    `/api/dms/net-docs/container/${destinationId}/folders`,
    { name: folderName }
  )

  if (result.isError) {
    throw new Error(result.data.error)
  }

  return result.data
}
