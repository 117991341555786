import { ReactNode } from "react"
import { NotificationList } from "./"
import FormattedNotificationListItem from "./FormattedNotificationListItem"
/**
 * Formats a collection of notifications into a list and styles them appropriately.
 *
 * @param {properties} ListProps - A collection of properties configuring the component. These include the
 * notifications to display, a title for the list, if the list contains viewed notifications, any actions
 * to enable for this list, and finally a limit on the number of notifications to display.
 */

type FormattedNotificationListProps = {
  notifications?: any
  read?: boolean
  type?: string
  extraData?: Record<string, any>
  downloadUrls?: Record<string, any>
  createdAt?: string
  title?: string
  viewed?: boolean
  action?: ReactNode
  limit?: number
}

const FormattedNotificationList = ({
  notifications,
  title,
  viewed = false,
  action,
  limit = Number.POSITIVE_INFINITY,
}: FormattedNotificationListProps) => {
  const handleClearAll = () => {
    // TODO - backend doesn't support this yet.
  }

  return (
    <NotificationList viewed={viewed} delay={0}>
      <NotificationList.TitleBar>
        <NotificationList.Title color="white">{title}</NotificationList.Title>
        {action && (
          <NotificationList.Action onClick={handleClearAll}>
            {action}
          </NotificationList.Action>
        )}
      </NotificationList.TitleBar>
      {notifications.slice(0, limit).map(({ id, ...props }) => (
        <FormattedNotificationListItem {...props} id={id} key={id} />
      ))}
    </NotificationList>
  )
}

export default FormattedNotificationList
