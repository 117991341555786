import { Intent, Icon } from "@blueprintjs/core"

import pluralize from "helpers/pluralize"
import ColorSpinner from "components/spinners/ColorSpinner"
import StepError from "./StepError"

type Step1Props = {
  progress?: number
  error?: Record<string, any>
  isMultiFile?: boolean
  status?: any
}

const Step1 = ({ progress = 0, error, status, isMultiFile }: Step1Props) => {
  return (
    <>
      <div className="flex justify-between">
        <h4 className="font-bold">
          {status === "IN_PROGRESS"
            ? `1. Downloading ${pluralize("file", isMultiFile ? 2 : 1)}...`
            : `1. Download ${pluralize("file", isMultiFile ? 2 : 1)}`}
        </h4>
        {status === "IN_PROGRESS" && (
          <ColorSpinner intent={Intent.PRIMARY} size={24} value={progress} />
        )}
        {status === "COMPLETE" && (
          <Icon icon="tick-circle" size={24} intent={Intent.SUCCESS} />
        )}
        {status === "ERROR" && (
          <Icon icon="error" size={24} intent={Intent.DANGER} />
        )}
      </div>
      {error && (
        <StepError
          error={error}
          message="We ran into a problem attempting to download the file(s)."
        />
      )}
    </>
  )
}

export default Step1
