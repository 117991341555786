import {
  Button,
  FormGroup,
  NumericInput,
  Intent,
  Spinner,
  Switch,
  TagInput,
  TextArea,
  InputGroup,
  Icon,
  Position,
} from "@blueprintjs/core"
import { motion } from "framer-motion"
import useToaster from "helpers/toaster"
import { isValid as isEmailValid } from "helpers/validations/email"

import { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { postFileShare } from "helpers/api"
import pluralize from "helpers/pluralize"
import { ErrorClient } from "app/config/services/error-reporting"
import {
  SHARE_LINK_SCHEMA,
  PASSWORD_MESSAGE,
  LINK_EXPIRATION_DAYS_MAXIMUM,
  LINK_EXPIRATION_DAYS_MINIMUM,
  DOWNLOAD_LIMIT_COUNT_MINIMUM,
  DOWNLOAD_LIMIT_COUNT_MAXIMUM,
  LINK_EXPIRATION_DAYS_MESSAGE,
  DOWNLOAD_LIMIT_COUNT_MESSAGE,
} from "./share-link-utils"
import { Tooltip2 } from "@blueprintjs/popover2"

type ShareSecureDownloadLinkProps = {
  documentType: string
  extraData?: Record<string, any>
  id: string
  onClick?: () => void
  onClose?: () => void
  transactionId: string
}

export default function ShareSecureDownloadLink({
  documentType,
  extraData,
  id,
  onClick = () => null,
  onClose = () => null,
  transactionId,
}: ShareSecureDownloadLinkProps) {
  const [submitting, setSubmitting] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [recipientEmails, setRecipientEmails] = useState<string[]>([])
  const [recipientEmailsTouched, setRecipientEmailsTouched] = useState(false)
  const [ccEmails, setCcEmails] = useState<string[]>([])
  const [ccEmailError, setCcEmailError] = useState(false)
  const [daysText, setDaysText] = useState("days")
  const [downloadsText, setDownloadsText] = useState("downloads")

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(SHARE_LINK_SCHEMA),
    defaultValues: {
      linkExpiration: false,
      downloadLimit: false,
      requirePassword: false,
      password: undefined,
      confirmPassword: undefined,
      message: `Please click the button below to download the closing binder for the following transaction: ${extraData?.target.name}.`,
    },
  })

  const linkExpirationValue = watch("linkExpiration")
  const downloadLimitValue = watch("downloadLimit")
  const requirePasswordValue = watch("requirePassword")

  const { failure, success } = useToaster()

  function handleSave(data: {
    linkExpiration?: boolean
    linkExpirationDays?: number
    downloadLimit?: boolean
    downloadLimitCount?: number
    requirePassword?: boolean
    password?: string
    confirmPassword?: string
    message?: string
  }) {
    if (!recipientEmailsTouched) {
      // set this to true to show validation errors
      setRecipientEmailsTouched(true)
      return
    }

    if (
      Object.keys(errors).length ||
      !recipientEmailsTouched ||
      isEveryEmailValid(recipientEmails) === false ||
      recipientEmails.length === 0
    ) {
      return
    }

    // check if any of the cc emails are also in the recipients list
    // SendGrid fails if any match
    if (recipientEmails.length > 0 && ccEmails.length > 0) {
      if (recipientEmails.some((email) => ccEmails.includes(email))) {
        setCcEmailError(true)
        return
      } else {
        setCcEmailError(false)
      }
    }

    setSubmitting(true)

    // base payload with required and nullable fields
    let payload: Record<string, any> = {
      id,
      document_type: documentType,
      recipients: recipientEmails || [],
      cc_recipients: ccEmails || [],
      message: data.message || "",
      transaction: transactionId,
    }

    // conditionally add properties to payload if they are set, otherwise leave them out
    // as the backend does not accept null values for expires_at, download_max, or password
    if (data.linkExpiration && data.linkExpirationDays) {
      payload.expires_at = new Date(
        new Date().setDate(new Date().getDate() + data.linkExpirationDays)
      )
    }

    if (data.downloadLimit) {
      payload.download_max = data.downloadLimitCount
    }

    if (data.requirePassword) {
      payload.password = data.password
    }

    const recipientsLength = [...payload.recipients, ...payload.cc_recipients]
      .length

    postFileShare(payload)
      .then((response) => {
        // assume error if we don't get the recipients back
        if (!response.recipients) {
          failure(
            "Something went wrong generating the link. Please try again in a few minutes or contact our support team if the issue persists."
          )
        } else {
          success(
            `Secure download link was sent to ${recipientsLength} ${pluralize(
              "recipient",
              recipientsLength
            )}`
          )
        }
        setSubmitting(false)
        onClose()
      })
      .catch((error) => {
        ErrorClient.notify(error)
        failure(
          "Something went wrong generating the link. Please try again in a few minutes or contact our support team if the issue persists."
        )
        setSubmitting(false)
      })
  }

  const isEveryEmailValid = (emails: string[]) => emails.every(isEmailValid)
  const getIntent = (field: keyof typeof errors) =>
    errors[field] ? Intent.DANGER : Intent.NONE

  const recipientEmailsIsInvalid =
    (recipientEmails.length === 0 && recipientEmailsTouched) ||
    !isEveryEmailValid(recipientEmails)

  const { ref: linkExpirationRef, ...linkExpirationProps } = register(
    "linkExpiration",
    {
      onChange: () => {
        setValue("linkExpiration", !linkExpirationValue)
        if (!linkExpirationValue) {
          setValue("linkExpirationDays", 7)
        } else {
          setValue("linkExpirationDays", undefined)
        }
      },
    }
  )
  const { ref: linkExpirationDaysRef } = register("linkExpirationDays", {
    valueAsNumber: true,
  })
  const { ref: downloadLimitRef, ...downloadLimitProps } = register(
    "downloadLimit",
    {
      onChange: () => {
        setValue("downloadLimit", !downloadLimitValue)
        if (!downloadLimitValue) {
          setValue("downloadLimitCount", 5)
        } else {
          setValue("downloadLimitCount", undefined)
        }
      },
    }
  )
  const { ref: downloadLimitCountRef } = register("downloadLimitCount", {
    valueAsNumber: true,
  })
  const { ref: requirePasswordRef, ...requirePasswordProps } = register(
    "requirePassword",
    {
      onChange: () => {
        setValue("requirePassword", !requirePasswordValue)
      },
    }
  )
  const { ref: passwordRef, ...passwordProps } = register("password", {
    required: true,
  })
  const { ref: confirmPasswordRef, ...confirmPasswordProps } = register(
    "confirmPassword",
    {
      required: requirePasswordValue,
    }
  )
  const { ref: messageRef, ...messageProps } = register("message")

  return (
    <motion.div
      key="share-options"
      className="inner"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="w-[500px] px-5 py-4">
          <div className="mb-4 rounded border border-gray-300 bg-gray-100 px-5 py-4">
            <h3 className="mb-4 text-base">Security Options</h3>
            <div className="mb-2 flex items-center gap-2">
              <Switch
                className="mt-2 w-6/12"
                labelElement={
                  <label htmlFor="linkExpiration" className="text-sm">
                    Set link expiration:
                  </label>
                }
                inputRef={linkExpirationRef}
                id="linkExpiration"
                large
                {...linkExpirationProps}
              />
              <div className="w-3/12">
                <NumericInput
                  fill
                  defaultValue={7}
                  id="linkExpirationDays"
                  name="linkExpirationDays"
                  inputRef={linkExpirationDaysRef}
                  placeholder="# of days"
                  disabled={!linkExpirationValue}
                  min={LINK_EXPIRATION_DAYS_MINIMUM}
                  max={LINK_EXPIRATION_DAYS_MAXIMUM}
                  minorStepSize={null}
                  majorStepSize={null}
                  stepSize={1}
                  onKeyDown={(e) => e.preventDefault()}
                  onValueChange={(val) => {
                    setDaysText(pluralize("day", val))
                    setValue("linkExpirationDays", val)
                  }}
                />
              </div>
              <span className="w-3/12 select-none">
                {daysText}
                <Tooltip2
                  content={LINK_EXPIRATION_DAYS_MESSAGE}
                  position={Position.RIGHT}
                  popoverClassName="max-w-xs"
                >
                  <Icon icon="info-sign" className="ml-2 text-gray-500" />
                </Tooltip2>
              </span>
            </div>
            <div className="mb-2 flex items-center gap-2">
              <Switch
                className="mt-2 w-6/12"
                labelElement={
                  <label htmlFor="downloadLimit" className="text-sm">
                    Set download limit:
                  </label>
                }
                inputRef={downloadLimitRef}
                id="downloadLimit"
                large
                {...downloadLimitProps}
              />
              <div className="w-3/12">
                <NumericInput
                  fill
                  defaultValue={5}
                  id="downloadLimitCount"
                  name="downloadLimitCount"
                  inputRef={downloadLimitCountRef}
                  placeholder="# of downloads"
                  disabled={!downloadLimitValue}
                  min={DOWNLOAD_LIMIT_COUNT_MINIMUM}
                  max={DOWNLOAD_LIMIT_COUNT_MAXIMUM}
                  minorStepSize={null}
                  majorStepSize={null}
                  stepSize={1}
                  onKeyDown={(e) => e.preventDefault()}
                  onValueChange={(val) => {
                    setDownloadsText(pluralize("download", val))
                    setValue("downloadLimitCount", val)
                  }}
                />
              </div>
              <span className="w-3/12 select-none">
                {downloadsText}
                <Tooltip2
                  content={DOWNLOAD_LIMIT_COUNT_MESSAGE}
                  position={Position.RIGHT}
                  popoverClassName="max-w-xs"
                >
                  <Icon icon="info-sign" className="ml-2 text-gray-500" />
                </Tooltip2>
              </span>
            </div>
            <div className="mb-2 flex gap-1">
              <Switch
                large
                className="mt-2 w-1/2 self-start"
                labelElement={
                  <>
                    <label htmlFor="requirePassword" className="text-sm">
                      Require a password:
                    </label>
                    <br />
                    <small className="block text-xs leading-tight text-gray-500">
                      {PASSWORD_MESSAGE}
                    </small>
                  </>
                }
                inputRef={requirePasswordRef}
                id="requirePassword"
                {...requirePasswordProps}
              />
              <div className="w-1/2">
                <FormGroup
                  label=""
                  labelFor="password"
                  helperText={
                    requirePasswordValue && errors?.password
                      ? errors.password.message
                      : ""
                  }
                  intent={getIntent("password")}
                >
                  <InputGroup
                    className="mt-2"
                    inputRef={passwordRef}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Set password..."
                    disabled={!requirePasswordValue}
                    intent={getIntent("password")}
                    rightElement={
                      requirePasswordValue ? (
                        <Tooltip2
                          position={Position.RIGHT}
                          content={`${showPassword ? "Hide" : "Show"} password`}
                        >
                          <Button
                            icon={showPassword ? "eye-open" : "eye-off"}
                            minimal={true}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </Tooltip2>
                      ) : undefined
                    }
                    required={requirePasswordValue}
                    data-1p-ignore
                    {...passwordProps}
                  />
                </FormGroup>
                <FormGroup
                  label=""
                  labelFor="confirmPassword"
                  helperText={
                    requirePasswordValue && errors?.confirmPassword
                      ? errors.confirmPassword.message
                      : ""
                  }
                  intent={getIntent("confirmPassword")}
                >
                  <InputGroup
                    className="mt-2"
                    inputRef={confirmPasswordRef}
                    id="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm password..."
                    disabled={!requirePasswordValue}
                    intent={getIntent("confirmPassword")}
                    data-1p-ignore
                    {...confirmPasswordProps}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <FormGroup label="Message for recipients">
            <TextArea rows={4} fill inputRef={messageRef} {...messageProps} />
          </FormGroup>
          <FormGroup
            label="Add recipients"
            helperText={
              recipientEmailsIsInvalid
                ? "At least one recipient is required"
                : ""
            }
            intent={recipientEmailsIsInvalid ? Intent.DANGER : undefined}
          >
            <div
              onKeyDown={(evt) => {
                // if tab key or space key is pressed, setRecipientEmailsTouched to true
                // since we have now added our first recipient
                if (evt.key === "Tab" || evt.key === " ") {
                  setRecipientEmailsTouched(true)
                }

                // prevent form submissions with enter key
                if (evt.key === "Enter") {
                  evt.preventDefault()
                }
              }}
            >
              <TagInput
                intent={recipientEmailsIsInvalid ? Intent.DANGER : undefined}
                addOnBlur
                leftIcon="envelope"
                placeholder="Add email address..."
                values={recipientEmails}
                onChange={(vals) => {
                  // set touched to true so the handleSubmit doesn't fail at the early return when
                  // addOnBlur is the thing that triggered the onChange here
                  // i.e. entering a valid email address and then clicking the submit button next
                  // without clicking out of the input first
                  setRecipientEmailsTouched(true)
                  setRecipientEmails(vals)
                }}
                tagProps={{
                  intent: isEveryEmailValid(recipientEmails)
                    ? Intent.NONE
                    : Intent.DANGER,
                }}
              />
            </div>
          </FormGroup>
          <FormGroup
            label={`Add "cc" recipients`}
            helperText={
              ccEmailError
                ? "A CC email recipient cannot match the email of a recipient"
                : null
            }
            intent={
              ccEmailError || !isEveryEmailValid(ccEmails)
                ? Intent.DANGER
                : undefined
            }
          >
            <div
              onKeyDown={(evt) => {
                if (evt.key === "Enter") evt.preventDefault()
              }}
            >
              <TagInput
                intent={
                  !isEveryEmailValid(ccEmails) ? Intent.DANGER : undefined
                }
                addOnBlur
                leftIcon="envelope"
                placeholder={`Add "cc" email address...`}
                values={ccEmails}
                onChange={(vals) => setCcEmails(vals)}
                tagProps={{
                  intent: isEveryEmailValid(ccEmails)
                    ? Intent.NONE
                    : Intent.DANGER,
                }}
              />
            </div>
          </FormGroup>
        </div>
        <div className="flex justify-end px-5 py-4">
          <Button minimal className="mr-3" onClick={onClick}>
            <div className="text-blue-9">Back</div>
          </Button>
          <Button
            intent={Intent.PRIMARY}
            icon={submitting ? <Spinner size={16} /> : "envelope"}
            disabled={submitting}
            type="submit"
          >
            Share file
          </Button>
        </div>
      </form>
    </motion.div>
  )
}
