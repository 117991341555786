import { Card } from "@blueprintjs/core"
import styled from "@emotion/styled"

const NotificationActionCard = styled(Card)`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto;
  grid-template-areas: "icon header" "icon description";
  grid-gap: 0 19px;
  width: 100%;
  padding: 16px;
  box-shadow: none;
  border: 1px solid #5a829e;

  .action-icon {
    grid-area: icon;
    align-self: center;
    justify-self: center;
  }

  h4 {
    grid-area: header;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #5a829e;
    margin: 0;
  }

  p {
    margin: 0;
    grid-area: description;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #5a829e;
  }
`
export default NotificationActionCard
