import * as yup from "yup"

export const PASSWORD_MINIMUM_LENGTH = 4
export const PASSWORD_MAXIMUM_LENGTH = 24
export const LINK_EXPIRATION_DAYS_MINIMUM = 1
export const LINK_EXPIRATION_DAYS_MAXIMUM = 30
export const DOWNLOAD_LIMIT_COUNT_MINIMUM = 1
export const DOWNLOAD_LIMIT_COUNT_MAXIMUM = 10

export const PASSWORD_MESSAGE = `Must be between ${PASSWORD_MINIMUM_LENGTH}-${PASSWORD_MAXIMUM_LENGTH} characters`
export const LINK_EXPIRATION_DAYS_MESSAGE = `Download links will expire after the set time period, up to ${LINK_EXPIRATION_DAYS_MAXIMUM} days`
export const DOWNLOAD_LIMIT_COUNT_MESSAGE = `Download links will expire after a set number of downloads is reached, up to ${DOWNLOAD_LIMIT_COUNT_MAXIMUM} total downloads`

export const SHARE_LINK_SCHEMA = yup
  .object({
    linkExpiration: yup.boolean(),
    linkExpirationDays: yup
      .number()
      .positive()
      .integer()
      .min(LINK_EXPIRATION_DAYS_MINIMUM)
      .max(LINK_EXPIRATION_DAYS_MAXIMUM),
    downloadLimit: yup.boolean(),
    downloadLimitCount: yup
      .number()
      .positive()
      .integer()
      .min(DOWNLOAD_LIMIT_COUNT_MINIMUM)
      .max(DOWNLOAD_LIMIT_COUNT_MAXIMUM),
    requirePassword: yup.boolean(),
    password: yup
      .string()
      .trim()
      .when("requirePassword", {
        is: (field: boolean) => field === true,
        then: () =>
          yup
            .string()
            .test("len", PASSWORD_MESSAGE, (val) => {
              if (val === undefined) {
                return true
              }
              return (
                val.length === 0 ||
                (val.length >= PASSWORD_MINIMUM_LENGTH &&
                  val.length <= PASSWORD_MAXIMUM_LENGTH)
              )
            })
            .required(
              'Password is required when "Require a password" is checked'
            ),
      }),
    confirmPassword: yup
      .string()
      .trim()
      .when("requirePassword", {
        is: (field: boolean) => field === true,
        then: () =>
          yup
            .string()
            .required("Please confirm your password")
            .oneOf(
              [yup.ref("password")],
              "Password must match the previous entry"
            ),
      }),
    message: yup.string().trim(),
  })
  .required()
