import { Icon, Intent } from "@blueprintjs/core"
import { Tooltip2 as Tooltip } from "@blueprintjs/popover2"

import ColorSpinner from "components/spinners/ColorSpinner"
import pluralize from "helpers/pluralize"
import FileUploadRow from "./FileUploadRow"

type Step3Props = {
  status?: string
  filesById?: Record<string, any>
  onRetryUpload?: () => void
}

const Step3 = ({ status, filesById, onRetryUpload = () => {} }: Step3Props) => {
  let files = Object.values(filesById || {})
  const fileSuccessfulOrProgressComplete = (file) =>
    file.status === "success" || file.progress === 1
  let filesUploaded = files.filter((file) =>
    fileSuccessfulOrProgressComplete(file)
  )
  let filesWithError = files.filter((file) => file.status === "error")
  let isError = filesWithError.length > 0
  let isDone =
    Boolean(files.length) && files.every((file) => Boolean(file.status))
  let isSingleFile = files.length === 1
  let isShowingFilesList = status === "IN_PROGRESS"

  return (
    <>
      <div className="flex justify-between">
        <h4 className="font-bold">
          {status === "IN_ACTIVE"
            ? `3. Upload ${pluralize("file", isSingleFile ? 1 : 2)}`
            : status === "COMPLETE"
              ? `3. Uploaded ${pluralize("file", isSingleFile ? 1 : 2)}`
              : `3. Uploading ${pluralize("file", isSingleFile ? 1 : 2)}...`}
        </h4>
        {status !== "IN_ACTIVE" && (
          <>
            {!isDone && !isSingleFile && (
              <ColorSpinner
                intent={Intent.PRIMARY}
                size={24}
                value={filesUploaded.length / files.length}
              />
            )}
            {isDone && filesUploaded.length === files.length && (
              <Icon icon="tick-circle" intent={Intent.SUCCESS} size={24} />
            )}
            {isDone && isError && (
              <Tooltip
                content={
                  <div className="w-64">
                    <p>
                      We were unable to successfully upload some of the files
                      below. Please try uploading again. If the problem
                      persists, please reach out to customer support at{" "}
                      <i>support@simplyagree.com</i>.
                    </p>
                  </div>
                }
                intent={Intent.DANGER}
              >
                <Icon icon="error" intent={Intent.DANGER} size={24} />
              </Tooltip>
            )}
          </>
        )}
      </div>
      {isDone && !isError && (
        <div className="text-xs text-slate-400">
          {filesUploaded.length} {pluralize("file", isSingleFile ? 1 : 2)}{" "}
          uploaded successfully.
        </div>
      )}
      {isShowingFilesList && (
        <ul
          className="mt-4 overflow-hidden overflow-y-auto pl-3 pr-1 text-md"
          style={{ maxHeight: "12rem" }} // 6 lines plus a peak at the 7th
        >
          {files.map((file) => (
            <FileUploadRow
              file={file}
              key={file.id}
              canTryAgain={isDone}
              onTryAgain={onRetryUpload}
            />
          ))}
        </ul>
      )}
    </>
  )
}

export default Step3
