import { ReactNode } from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 314px;

  & > div {
    margin-bottom: 8px;
  }

  ${(props) =>
    props.viewed &&
    `
        opacity: 40%;
    `}
`

const variant = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.3,
      staggerDirection: -1,
    },
  },
}

/**
 * Handles rendering a collection of Notification components.
 *
 * @param {ReactNode} children - Nested JSX.
 * @param {boolean} viewed - A flag indicating this notification has been viewed.
 */

type NotificationListProps = {
  children?: ReactNode
  viewed?: boolean
  delay?: number
}

export const NotificationList = ({
  children,
  delay = 0,
}: NotificationListProps) => {
  return (
    <motion.div
      custom={delay}
      animate="visible"
      initial="hidden"
      variants={variant}
    >
      <Container>{children}</Container>
    </motion.div>
  )
}

const TitleBar = styled.div`
  display: grid;
  grid-template-areas: "title action";
`

const Title = styled.div`
  grid-area: title;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.color || "#182026"};
  text-align: left;
  line-height: 18px;
`

const Action = styled.div`
  grid-area: action;
  justify-self: end;
  cursor: pointer;
`

NotificationList.TitleBar = TitleBar
NotificationList.Title = Title
NotificationList.Action = Action
