import { useState, useEffect } from "react"
import { Button, Intent, Icon, FormGroup, InputGroup } from "@blueprintjs/core"
import { useForm } from "react-hook-form"

import StepError from "./StepError"

type Step2Props = {
  error?: Record<string, any>
  success?: boolean
  onFolderNameSubmit: (value: string) => void
  savedFolderName?: string
  status?: any
  destinationType?: any
  destinationName?: string
}

const Step2 = ({
  error,
  onFolderNameSubmit,
  savedFolderName,
  status,
  success,
  destinationName,
  destinationType,
}: Step2Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      folderName: "",
    },
  })

  useEffect(() => {
    // listen for when the folder is created successfully or an error occurs
    if (success || error) {
      setIsSaving(false)
    }
  }, [success, error])

  const watchedFolderName = watch("folderName")

  const isFormDisabled =
    (status !== "IN_PROGRESS" && status !== "ERROR") || isSaving

  const handleSave = (values: { folderName: string }) => {
    setIsSaving(true)
    onFolderNameSubmit(values.folderName)
  }

  // Error message back from NetDocs, if it already exists we want to show a different error message
  // and allow the user to try again
  const folderAlreadyExists = error?.message.includes("already contains")

  const { ref: folderNameRef, ...folderNameRest } = register("folderName")

  return (
    <>
      <div className="flex justify-between">
        <h4 className="font-bold">2. File Location</h4>

        {status === "COMPLETE" && success && (
          <Icon icon="tick-circle" size={24} intent={Intent.SUCCESS} />
        )}
      </div>

      {status !== "COMPLETE" && (
        <form onSubmit={handleSubmit(handleSave)} className="mt-4">
          <fieldset disabled={isFormDisabled}>
            <p className="mb-4">You are currently saving to:</p>
            <p className="mb-4">
              <img
                src={
                  destinationType === "folder"
                    ? "/static/nd-folder.svg"
                    : "/static/nd-workspace.svg"
                }
                width={18}
                className="mr-2 inline-block"
              />
              {destinationName}
            </p>

            <FormGroup
              label="You can organize these documents in a subfolder before you save. Set the subfolder name or skip:"
              labelFor="folderName"
              helperText={
                folderAlreadyExists
                  ? "This folder already exists in this NetDocuments location. Please choose a new folder name."
                  : null
              }
              intent={folderAlreadyExists ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                autoFocus={status === "IN_PROGRESS"}
                inputRef={folderNameRef}
                id="folderName"
                fill
                placeholder="e.g., Closing documents"
                intent={folderAlreadyExists ? Intent.DANGER : Intent.NONE}
                {...folderNameRest}
              />
            </FormGroup>
            <div className="mb-1 flex justify-end">
              <Button
                minimal
                className="mr-2"
                onClick={() => onFolderNameSubmit("")}
              >
                Skip
              </Button>

              <Button
                disabled={!watchedFolderName}
                type="submit"
                intent={Intent.PRIMARY}
                loading={isSaving}
              >
                Create
              </Button>
            </div>
          </fieldset>
        </form>
      )}
      {success && status === "COMPLETE" && savedFolderName && (
        <div className="text-xs text-slate-500">
          Folder created: <em>{savedFolderName}</em>
        </div>
      )}

      {(status === "ERROR" || error) && !folderAlreadyExists && (
        <StepError
          error={error}
          message={`We ran into a problem attempting to create a folder with the name ${watchedFolderName}.`}
        />
      )}
    </>
  )
}

export default Step2
