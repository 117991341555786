import { Icon, Intent } from "@blueprintjs/core"

// export const SIGNATURES_RECEIVED = ""; // TODO: this is not yet implemented in the backend
export const CLOSING_BINDER_DOWNLOAD = "closing_binder_download"
export const CONVERSION_FAILURE = "conversion_failure"
export const SIGNING_MATRIX_REPORT = "signing_matrix_report"
export const SIGNING_STATUS_REPORT = "signing_status_report"
export const ITEM_DOWNLOAD_READY = "item_download_ready"
export const ORG_REPORT_READY = "org-report"
export const TRANSACTION_DOWNLOAD_READY = "transaction_download_ready"

/**
 * Returns the appropriate notification icon based on the event type.
 *
 * @param {string} eventType - The type of notification, can be one of the enums above.
 */
export const determineIcon = (eventType: string | undefined) => {
  switch (eventType) {
    case CONVERSION_FAILURE:
      return <Icon icon="error" intent={Intent.DANGER} size={20} />
    case CLOSING_BINDER_DOWNLOAD:
    case SIGNING_MATRIX_REPORT:
    case SIGNING_STATUS_REPORT:
    case ITEM_DOWNLOAD_READY:
    case ORG_REPORT_READY:
    case TRANSACTION_DOWNLOAD_READY:
    default:
      return <Icon icon="download" size={20} intent={Intent.PRIMARY} />
  }
}

/**
 * Returns the appropriate sub-title based on the event type.
 *
 * @param {string} eventType - The type of notification, can be one of the enums above.
 * @param {object} name - Extracts the name from an object of shape `{ actor_text, target: { name, item_name } }`, which is the
 * name of the document.
 */
type ExtraData = {
  actor_text?: string
  target?: {
    name?: string
    item_name?: string
  }
}

export const determineSubtitle = (
  eventType: string,
  { actor_text, target: { name, item_name } = {} }: ExtraData = {}
) => {
  switch (eventType) {
    case CONVERSION_FAILURE:
      return `${name} failed to upload`
    case CLOSING_BINDER_DOWNLOAD:
      return "Closing binder is ready"
    case SIGNING_STATUS_REPORT:
      return "Signer report is ready"
    case SIGNING_MATRIX_REPORT:
      return "Signing matrix is ready"
    case TRANSACTION_DOWNLOAD_READY:
      return actor_text ? `${actor_text} are ready` : "Documents are ready"
    case ITEM_DOWNLOAD_READY:
      if (item_name) {
        return `${item_name} ready`
      }
      return "Document download ready"
    case ORG_REPORT_READY:
      return "Organization report is ready"
    default:
      return ""
  }
}

/**
 * Return the transaction id from a notification by digging into the `extraData` object.
 * Used for passing the transactionId another component to then use for fetching transaction details
 * using the useTransaction hook.
 * @param {notificationTarget} notificationTarget the notification extraData target object
 * @returns string transaction id
 */
export const getTransactionIdFromNotification = (
  notificationTarget:
    | {
        transactionId?: string
        uuid?: string
        link?: string
        frontend_link?: string
      }
    | undefined
): string => {
  if (!notificationTarget) {
    return ""
  }

  if (notificationTarget.transactionId) {
    return notificationTarget.transactionId
  }

  const linkSplit = notificationTarget.link?.split("/")
  // Sometimes link looks like "https://domain/transaction/transactionId"
  // but othertimes it is only "http://domain/notifications", so only use it if it has a transactionId
  if (linkSplit && linkSplit.length >= 5) {
    return notificationTarget.link?.split("/")[4] || ""
  }
  if (notificationTarget.frontend_link) {
    return (
      notificationTarget.frontend_link.split("/")[
        // some links seem to have domain, others start after with a slash
        notificationTarget.frontend_link.includes("https://app.simplyagree.")
          ? 4
          : 2
      ] || ""
    )
  }

  return ""
}
