import * as React from "react"

import { Dialog, Icon } from "@blueprintjs/core"

import { useCurrentUser } from "features/auth/withAuth"
import IMSaveDialog from "features/dms/IM/SaveTo"
import NDSaveDialog from "features/dms/NetDocs/SaveTo"

type DMSSaveDialogProps = {
  onClose?: () => void
  transactionId?: string
  fileUrl: string
}

export default function DMSSaveDialog({
  onClose = () => null,
  fileUrl,
  transactionId,
}: DMSSaveDialogProps) {
  let { currentUser } = useCurrentUser()

  let Component =
    currentUser?.dmsType === "IMANAGE"
      ? IMSaveDialog
      : currentUser?.dmsType === "NET_DOCS"
        ? NDSaveDialog
        : React.Fragment

  return (
    <Dialog className="relative h-[80vh] w-[80vw] p-0" isOpen onClose={onClose}>
      <button
        className="absolute right-[-32px] top-[-32px] flex h-8 w-8 rounded-full bg-gray-300 hover:bg-gray-100"
        onClick={onClose}
      >
        <Icon icon="cross" className="m-auto" />
      </button>
      {fileUrl && (
        <Component
          onClose={onClose}
          fileUrl={fileUrl}
          transactionId={transactionId || ""}
        />
      )}
    </Dialog>
  )
}
