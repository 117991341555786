import ConcurrentRunner from "lib/concurrent-runner"

export default async function uploadDocumentToND(
  id,
  folderID,
  fileBlob,
  onProgress = () => {}
) {
  let response = await new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    let url = `/api/dms/net-docs/container/${folderID}/documents`
    xhr.open("POST", url)
    xhr.upload.addEventListener(
      "progress",
      (event) => {
        if (event.lengthComputable) {
          onProgress(id, event.loaded / event.total)
        }
      },
      false
    )

    xhr.addEventListener("loadend", (event) => {
      resolve(event.target)
    })

    xhr.addEventListener("error", (event) => {
      console.error("error", event)
      reject(event)
    })

    let data = new FormData()
    data.append("file", fileBlob)
    data.append("destination", folderID)
    xhr.send(data)
  })

  if (response.status > 299) {
    throw new Error(response.statusText + " " + response.responseText)
  }

  return response
}

export async function bulkUploadDocumentsToND(
  folderID,
  files,
  onProgress = () => {},
  { uploader = uploadDocumentToND } = {}
) {
  let results = await new ConcurrentRunner(files, { concurrency: 3 }).run(
    async (file) => {
      return await uploader(
        file.id,
        folderID,
        new File([file.blob], file.name),
        onProgress
      )
    }
  )

  return files.map((file, index) => {
    let status = results[index].status === "fulfilled" ? "success" : "error"
    file.status = status
    file.reason = results[index].reason
    return file
  })
}
