import { useEffect, useState } from "react"
import { Button, Intent } from "@blueprintjs/core"

import cx from "classnames"

import useToaster from "helpers/toaster"
import { useTransaction } from "features/transaction/id/api"
import useSaveFileToFolder from "./use-save-file-to-folder"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"

type SaveToProps = {
  onClose?: () => void
  transactionId: string
  fileUrl: string
}

export default function SaveTo({
  onClose,
  fileUrl,
  transactionId,
}: SaveToProps) {
  let { data: transaction } = useTransaction(transactionId)

  const { success } = useToaster()

  const [ndApplicationUrl, setNdApplicationUrl] = useState("")

  let { onSaveFileToFolder, onFolderNameSubmit, onRetryFileUpload, state } =
    useSaveFileToFolder({
      fileUrl,
    })

  useEffect(() => {
    function onSaveToNetDocs(evt) {
      onSaveFileToFolder({
        destinationId: evt.detail.destinationId,
        destinationType: evt.detail.destinationType,
        destinationName: evt.detail.destinationName,
      })
    }

    window.document.addEventListener("SAVE_TO_NETDOCS", onSaveToNetDocs)

    return () => {
      window.document.removeEventListener("SAVE_TO_NETDOCS", onSaveToNetDocs)
    }
  }, [])

  useEffect(() => {
    // need this useEffect because we are dependent on when the useTransaction hook finishes
    let queryParams = {
      transactionId,
      selection: "folder",
    }

    if (transaction?.matterNumber && transaction?.clientNumber) {
      queryParams = {
        ...queryParams,
        matterNum: transaction.matterNumber,
        clientNum: transaction.clientNumber,
      }
    }

    // if we have a client and matter number, we can open the correct workspace or folder
    // otherwise it should default to Recent Workspaces in NetDocs once opened by itself
    setNdApplicationUrl(`/dms/net-docs?${new URLSearchParams(queryParams)}`)
  }, [transaction, transactionId])

  const isInDownloadStep = ["download", "downloadError"].includes(state.value)
  const isInFolderStep = state.value.includes("Folder")
  const isInUploadStep = state.value.includes("upload")

  const hasError = state.value.includes("Error")

  const stepClasses = "mb-8 rounded p-4 bg-white border"
  const activeClasses = "shadow-lg border-gray-400"
  const errorClasses = "border-red-500"
  const completedClasses = "border-green-sa-1"
  const inactiveClasses = "opacity-50 cursor-not-allowed"

  const step1Classes = cx(stepClasses, {
    [activeClasses]: isInDownloadStep,
    [completedClasses]: !isInDownloadStep,
    [errorClasses]: isInDownloadStep && hasError,
  })

  const step2Classes = cx(stepClasses, {
    [inactiveClasses]: isInDownloadStep,
    [activeClasses]: isInFolderStep,
    [completedClasses]: isInUploadStep || state.value === "success",
    [errorClasses]: isInFolderStep && hasError,
  })

  const step3Classes = cx(stepClasses, {
    [inactiveClasses]: isInDownloadStep || isInFolderStep,
    [activeClasses]: isInUploadStep,
    [completedClasses]: state.value === "success",
    [errorClasses]: isInUploadStep && hasError,
  })

  // type can be "done", "error", or "cancel"
  const handleClose = (type) => {
    if (type === "done" && state.value === "success") {
      success("Your binder has been successfully saved.")
    }
    onClose()
  }

  return (
    <div className="relative h-full w-full">
      <iframe src={ndApplicationUrl} className="h-full w-full" />
      {state.value !== "idle" && (
        <div className="fixed inset-0 flex overflow-y-auto bg-gray-800 bg-opacity-60 p-8">
          <div className="m-auto w-1/2-screen max-w-[480px] rounded-sm bg-white px-8 pb-5 pt-8">
            <div className="mb-8 flex h-8 items-center justify-between">
              <div className="bg-white pr-2">
                <img
                  src="/static/sa-logo-primary-500.png"
                  className="w-[125px]"
                />
              </div>

              <div className="mx-3 h-0 flex-1 border" />
              <div className="bg-white pl-2">
                <img src="/static/netdocs-lg.png" className="w-[135px]" />
              </div>
            </div>
            <div
              className={step1Classes}
              aria-live={isInDownloadStep ? "polite" : "off"}
              role="status"
            >
              <Step1
                isMultiFile={fileUrl.includes(".zip")}
                progress={state.context.downloadProgress}
                error={state.context.downloadError}
                status={
                  state.value === "download"
                    ? "IN_PROGRESS"
                    : state.value === "downloadError"
                      ? "ERROR"
                      : !["download", "downloadError"].includes(state.value)
                        ? "COMPLETE"
                        : undefined
                }
              />
            </div>
            <div
              className={step2Classes}
              aria-live={isInFolderStep ? "polite" : "off"}
              role="log"
            >
              <Step2
                error={state.context.folderNameError}
                onFolderNameSubmit={onFolderNameSubmit}
                savedFolderName={state.context.folderName}
                destinationType={state.context.destinationType}
                destinationName={state.context.destinationName}
                status={
                  ["chooseFolderName", "createFolder"].includes(state.value)
                    ? "IN_PROGRESS"
                    : state.value === "createFolderError"
                      ? "ERROR"
                      : "COMPLETE"
                }
                success={state.context.folderCreatedSuccessfully}
              />
            </div>
            <div
              className={step3Classes}
              aria-live={
                isInUploadStep || state.value === "success" ? "polite" : "off"
              }
              role="status"
            >
              <Step3
                onRetryUpload={onRetryFileUpload}
                filesById={state.context.unZippedFiles}
                status={
                  isInUploadStep
                    ? "IN_PROGRESS"
                    : state.value === "success"
                      ? "COMPLETE"
                      : "IN_ACTIVE"
                }
              />
            </div>
            <div className="flex justify-end">
              {state.value !== "success" && (
                <Button
                  minimal
                  className="mr-2"
                  text="Cancel"
                  onClick={() => handleClose("cancel")}
                />
              )}
              <Button
                intent={Intent.PRIMARY}
                disabled={!["error", "success"].includes(state.value)}
                text={state.value === "error" ? "Close" : "Done"}
                onClick={() =>
                  handleClose(state.value === "error" ? "close" : "done")
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
