import { ReactNode } from "react"
import { Button, Callout } from "@blueprintjs/core"
import { AnimatePresence, motion } from "framer-motion"
import MultiFilePdfIcon from "icons/multi-file-pdf.svg"
import MultiFileWordIcon from "icons/multi-file-word.svg"
import SaveToComputerIcon from "icons/save-to-computer.svg"
import SaveToDMSIcon from "icons/save-to-dms.svg"
import ShareDownloadLinkIcon from "icons/share-download-link.svg"
import SingleFileIcon from "icons/single-file.svg"
import SingleFilePDFIcon from "icons/single-file-pdf.svg"
import OptionsIcon from "icons/options.svg"
import { useState } from "react"
import ShareSecureDownloadLink from "./ShareSecureDownloadLink"
import { useAppConfig } from "app/config"
import SAInfoModal from "components/modals/SAInfoModal"
import SAModal from "components/modals/SAModal"
import { useCurrentUser } from "features/auth/withAuth"
import DMSSaveDialog from "features/dms/DMSSaveDialog"
import NotificationActionCard from "./NotificationActionCard"
import { getTransactionIdFromNotification } from "./utils"

enum DOWNLOAD_TYPES {
  SINGLE_FILE = "pdf_file",
  MULTI_PDF = "zip_file",
  MULTI_WORD = "docx_zip_file",
  PORTFOLIO = "portfolio",
}

type FileDownloadDialogProps = {
  isOpen?: boolean
  onClose?: () => void
  downloadUrls?: Record<DOWNLOAD_TYPES, string>
  downloadHref?: string
  skipDownloadSelection?: boolean
  meta?: ReactNode
  linkText?: string
}

const FileDownloadDialog = ({
  isOpen = false,
  onClose = () => null,
  downloadUrls,
  downloadHref,
  skipDownloadSelection = false,
  meta,
  linkText = "View",
  ...rest
}: FileDownloadDialogProps) => {
  const { currentUser } = useCurrentUser()
  const { config } = useAppConfig()
  const [dialogOpen, setDialogOpen] = useState(isOpen)
  const [downloadSelection, setDownloadSelection] =
    useState<DOWNLOAD_TYPES | null>(null)
  const [showSendForm, setShowSendForm] = useState(false)
  const [showDMSDialog, setShowDMSDialog] = useState(false)

  const cleanup = () => {
    setDialogOpen(false)
    setShowSendForm(false)
    setDownloadSelection(null)
    setShowDMSDialog(false)
    onClose()
  }

  const backToDownloadSelection = () => {
    setDownloadSelection(null)
    setShowSendForm(false)
    setShowDMSDialog(false)
  }

  const backToSendForm = () => {
    setShowSendForm(false)
    setShowDMSDialog(false)
  }

  function handleSaveToDMS() {
    if (!downloadSelection) {
      // NOTE Auto select the pdf_file url if not set / selected
      setDownloadSelection(DOWNLOAD_TYPES.SINGLE_FILE)
    }
    setShowDMSDialog(true)
  }

  const isShowingSaveToIManage =
    currentUser?.imanage_configs?.length &&
    currentUser?.imanage_configs?.length > 0
  const isShowsingSaveToNetDocs = currentUser?.net_docs_config
  const showDownloadSelection = !skipDownloadSelection && !downloadSelection

  if (showSendForm && config?.TEST_DRIVE === "ENABLED") {
    return (
      <SAInfoModal
        header={
          <div className="text-center">
            <div>Closing binder links in the</div>
            <div>SimplyAgree test drive!</div>
          </div>
        }
        body={
          <div>
            <p>
              In a production deployment of SimplyAgree, we allow the user to
              send a closing binder link to one or more recipients.
            </p>
            <div className="p-8">
              <img
                className="rounded-lg border border-gray-200 shadow-2xl"
                src="/static/cb-link-ss.png"
              />
            </div>
          </div>
        }
        onClose={cleanup}
      />
    )
  }

  let hasPortfolioAvailable =
    config?.SWITCHES?.PDF_PORTFOLIO &&
    Boolean(downloadUrls?.[DOWNLOAD_TYPES.PORTFOLIO])

  let downloadUrl =
    (downloadSelection && downloadUrls?.[downloadSelection]) || downloadHref
  return (
    <>
      <a rel="nooper noreferrer" onClick={() => setDialogOpen(true)}>
        {linkText}
      </a>
      <SAModal
        isOpen={dialogOpen}
        onClose={cleanup}
        title={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={
                showDownloadSelection
                  ? "download-title-type"
                  : showSendForm
                    ? "download-link-type"
                    : "share-title-type"
              }
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="px-4 text-lg font-semibold"
            >
              {showDownloadSelection
                ? "Choose a closing binder format"
                : showSendForm
                  ? "Share a secure download link"
                  : "Choose where to save"}
            </motion.div>
          </AnimatePresence>
        }
        icon={<OptionsIcon width={26} height={24} viewBox="-1 2 24 22" />}
        body={
          <AnimatePresence exitBeforeEnter>
            {showDownloadSelection && (
              <motion.div
                key="download-types"
                className="inner w-[380px] px-4 pb-4 pt-0"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {meta ? (
                  <div className="mb-4">{meta}</div>
                ) : (
                  <div className="mb-4" />
                )}
                <div className="space-y-4">
                  {hasPortfolioAvailable && (
                    <>
                      <div className="float-right">
                        <Callout className="p-1 !text-[#065F46] !bg-[#D1FAE5] rounded-t-sm !rounded-b-none">
                          <strong>NEW!</strong>
                        </Callout>
                      </div>
                      <NotificationActionCard
                        className="!mt-0 !rounded-tr-none"
                        interactive
                        onClick={() =>
                          setDownloadSelection(DOWNLOAD_TYPES.PORTFOLIO)
                        }
                      >
                        <div className="action-icon">
                          <SingleFileIcon />
                        </div>
                        <h4>Single-file binder (portfolio)</h4>
                        <p>
                          A single-file PDF that links to each document as an
                          attachment for easier navigation.
                        </p>
                      </NotificationActionCard>
                    </>
                  )}
                  <NotificationActionCard
                    interactive
                    onClick={() =>
                      setDownloadSelection(DOWNLOAD_TYPES.SINGLE_FILE)
                    }
                  >
                    <div className="action-icon">
                      <SingleFilePDFIcon />
                    </div>
                    <h4>Single-file binder (standard)</h4>
                    <p>
                      A single PDF containing all documents with a hyperlinked
                      index and bookmarks.
                    </p>
                  </NotificationActionCard>
                  <NotificationActionCard
                    interactive
                    onClick={() =>
                      setDownloadSelection(DOWNLOAD_TYPES.MULTI_PDF)
                    }
                  >
                    <div className="action-icon">
                      <MultiFilePdfIcon />
                    </div>
                    <h4>Multi-file binder (PDF index)</h4>
                    <p>A .zip file with a PDF index and numbered documents.</p>
                  </NotificationActionCard>
                  <NotificationActionCard
                    interactive
                    onClick={() =>
                      setDownloadSelection(DOWNLOAD_TYPES.MULTI_WORD)
                    }
                  >
                    <div className="action-icon">
                      <MultiFileWordIcon />
                    </div>
                    <h4>Multi-file binder (Word index)</h4>
                    <p>A .zip file with a Word index and numbered documents.</p>
                  </NotificationActionCard>
                  <Button minimal onClick={cleanup} className="ml-auto flex">
                    <div className="text-blue-9">Cancel</div>
                  </Button>
                </div>
              </motion.div>
            )}
            {!showDownloadSelection && !showSendForm && downloadUrl && (
              <motion.div
                key="share-options"
                className="inner"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="w-[380px] space-y-4 p-4">
                  <a
                    href={downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-4 block hover:no-underline"
                  >
                    <NotificationActionCard
                      interactive
                      onClick={() => cleanup()}
                    >
                      <div className="action-icon">
                        <SaveToComputerIcon />
                      </div>
                      <h4>Save to computer.</h4>
                      <p>
                        Choose a location on your computer to save this file.
                      </p>
                    </NotificationActionCard>
                  </a>
                  {rest.type === "closing_binder_download" &&
                    config?.SHARE_DOWNLOAD_LINK === "ENABLED" && (
                      <NotificationActionCard
                        interactive
                        onClick={() => setShowSendForm(true)}
                      >
                        <div className="action-icon">
                          <ShareDownloadLinkIcon />
                        </div>
                        <h4>Share download link.</h4>
                        <p>
                          Send a link for another party to download this file.
                        </p>
                      </NotificationActionCard>
                    )}
                  {isShowingSaveToIManage && (
                    <>
                      <NotificationActionCard
                        className="mt-4"
                        interactive
                        onClick={handleSaveToDMS}
                      >
                        <div className="action-icon">
                          <SaveToDMSIcon />
                        </div>
                        <h4>Save to iManage.</h4>
                        <p>
                          Add this file to the firm&apos;s iManage document
                          management system.
                        </p>
                      </NotificationActionCard>
                      {showDMSDialog && (
                        <DMSSaveDialog
                          fileUrl={downloadUrl}
                          onClose={cleanup}
                          transactionId=""
                        />
                      )}
                    </>
                  )}

                  {isShowsingSaveToNetDocs &&
                    config?.FEATURES?.SAVE_TO_NET_DOCS === "ENABLED" && (
                      <>
                        <NotificationActionCard
                          className="mt-4"
                          interactive
                          onClick={handleSaveToDMS}
                        >
                          <div className="action-icon">
                            <SaveToDMSIcon />
                          </div>
                          <h4>Save to NetDocuments.</h4>
                          <p>Add this file to a NetDocuments workspace.</p>
                        </NotificationActionCard>
                        {showDMSDialog && (
                          <DMSSaveDialog
                            fileUrl={downloadUrl}
                            transactionId={getTransactionIdFromNotification(
                              rest.extraData.target
                            )}
                            onClose={cleanup}
                          />
                        )}
                      </>
                    )}

                  <Button
                    minimal
                    className="ml-auto flex"
                    onClick={
                      downloadSelection || showDMSDialog
                        ? backToDownloadSelection
                        : cleanup
                    }
                  >
                    <div className="text-blue-9">
                      {downloadSelection || showDMSDialog ? "Back" : "Cancel"}
                    </div>
                  </Button>
                </div>
              </motion.div>
            )}
            {showSendForm && (
              <ShareSecureDownloadLink
                {...rest}
                downloadUrls={downloadUrls}
                documentType={downloadSelection}
                onClose={cleanup}
                onClick={backToSendForm}
                transactionId={getTransactionIdFromNotification(
                  rest.extraData.target
                )}
              />
            )}
          </AnimatePresence>
        }
      ></SAModal>
    </>
  )
}

export default FileDownloadDialog
