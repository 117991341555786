import { ReactNode } from "react"
import styled from "@emotion/styled"

const SVG = styled.svg`
  display: inline-block;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) =>
    props.color &&
    `
      & path {
        fill: ${props.color};
      }
    
      & g {
        fill: ${props.color};
      }
  `}
`

type IconSVGProps = {
  children?: ReactNode
}

const IconSVG = ({ children, ...props }: IconSVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>{children}</g>
  </SVG>
)

export default IconSVG
