import { Button, Icon, Intent, ProgressBar } from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

type FileUploadRowProps = {
  file?: any
  id: string
  name: string
  status?: string
  progress?: number
  reason?: any
  canTryAgain?: boolean
  onTryAgain?: () => void
}

export default function FileUploadRow({
  canTryAgain,
  file,
  onTryAgain = () => {},
}: FileUploadRowProps) {
  let isSuccessfulOrProgressComplete =
    file.status === "success" || file.progress === 1

  return (
    <li key={file.name}>
      <div className="mb-4 flex w-full items-center justify-between gap-4">
        <div className="flex-1 truncate">{file.name}</div>
        {file.status === "error" && (
          <>
            {canTryAgain && file.status === "error" && (
              <Button small onClick={() => onTryAgain(file.id)}>
                Try again
              </Button>
            )}
            <Tooltip2
              intent={Intent.DANGER}
              content={
                <div>
                  <pre className="text-xs opacity-90">
                    {typeof file.reason === "string" ? (
                      file.reason
                    ) : typeof file.reason === "object" ? (
                      <>{file.reason.message}</>
                    ) : (
                      JSON.stringify(file.reason, null, 2)
                    )}
                  </pre>
                </div>
              }
            >
              <Icon className="ml-auto" icon="error" intent={Intent.DANGER} />
            </Tooltip2>
          </>
        )}
        {file.status !== "error" && (
          <div className="w-1/2 flex-shrink-0">
            <ProgressBar
              className="sa-progress-bar"
              value={file.status === "success" ? 100 : file.progress || 0}
              intent={
                isSuccessfulOrProgressComplete
                  ? Intent.SUCCESS
                  : file.status === "error"
                    ? Intent.DANGER
                    : Intent.PRIMARY
              }
              animate={
                !isSuccessfulOrProgressComplete && file.status !== "error"
              }
              stripes={
                !isSuccessfulOrProgressComplete && file.status !== "error"
              }
            />
          </div>
        )}
      </div>
    </li>
  )
}
