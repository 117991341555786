import { ReactNode } from "react"
import styled from "@emotion/styled"

import { Icon, ICONS } from "../icons"

const Container = styled.div`
  padding: 12px;
  font-size: 12px;
  color: #182026;
  text-align: left;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 28px 1fr max-content;
  line-height: 18px;
  border-radius: 3px;

  background-color: white;

  ${(props) =>
    props.viewed &&
    `
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    &:hover {
      opacity: 100%;
    }
  `}

  &:hover {
    opacity: 100%;
  }

  & .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`

/**
 * Represents a single notification card.
 *
 * @param {boolean} viewed - A flag indicating the notification has been viewed or not.
 * @param {ReactNode} icon - The icon to display for the notification.
 * @param {ReactNode} children - Nested JSX.
 */

type NotificationProps = {
  viewed?: boolean
  icon?: ReactNode
  children?: ReactNode
}

export const Notification = ({
  viewed = false,
  icon = <Icon icon={ICONS.errorCircle} />,
  children,
}: NotificationProps) => {
  return (
    <div className="w-[314px]">
      <Container viewed={viewed}>
        <div className="icon-container">{icon}</div>
        {children}
      </Container>
    </div>
  )
}

const BodyContainer = styled.div`
  display: grid;
  grid-template-areas:
    "title"
    "subtitle";
  margin-right: 5px;
`

const BodyTitle = styled.div`
  grid-area: title;
  text-transform: capitalize;
`

const BodySubtitle = styled.div`
  grid-area: subtitle;
  font-size: 11px;
  overflow: hidden;
  line-height: 1rem;
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & a {
    ${(props) => props.viewed && `color: white`}
  }

  &:hover {
    color: #5a829e;

    & svg path {
      fill: #5a829e;
    }
  }

  & svg {
    margin-left: 5px;

    & path {
      fill: #8b8f92;

      &:hover {
        fill: #5a829e;
      }
    }
  }
`

Notification.Body = BodyContainer
Notification.BodyTitle = BodyTitle
Notification.BodySubtitle = BodySubtitle
Notification.Action = Action
