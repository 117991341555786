import * as React from "react"

import { Button, Dialog, Intent } from "@blueprintjs/core"

type SAInfoModalProps = {
  onClose?: () => void
  body?: React.ReactNode
  header?: React.ReactNode
}

export default function SAInfoModal({
  body,
  header,
  onClose = () => null,
}: SAInfoModalProps) {
  return (
    <Dialog onClose={onClose} isOpen className="w-112 rounded-none p-0">
      <div
        className="flex h-32 bg-cover bg-left"
        style={{ backgroundImage: `url('/static/Star background.png')` }}
      >
        <div className="color-white m-auto text-lg tracking-widest">
          {header}
        </div>
      </div>
      <div className="p-5">
        {body}
        <div className="flex justify-end">
          <Button text="Done" onClick={onClose} intent={Intent.PRIMARY} />
        </div>
      </div>
    </Dialog>
  )
}
