import { Icon, IconProps } from "@blueprintjs/core"

import { useNotifications } from "features/NotificationsProvider"

type NotificationIconProps = {
  color?: string
  size?: IconProps["size"]
}

export const NotificationIcon = (props: NotificationIconProps) => {
  const { notifications } = useNotifications()

  const unreadNotifications = notifications.some((n) => !n.read)

  return unreadNotifications ? (
    <div
      className="relative m-auto inline-block"
      style={{ color: props.color || "inherit" }}
    >
      <Icon {...props} icon={"notifications"} />
      <div className="absolute right-0 -top-1 h-3 w-3 rounded-full bg-green-sa-1" />
      <div className="sa-animate-ping absolute right-0 -top-1 z-10 h-3 w-3 rounded-full bg-green-sa-1" />
    </div>
  ) : (
    <Icon {...props} icon={"notifications"} />
  )
}
