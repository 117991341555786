import { useMachine } from "@xstate/react"

import createSaveToNetDocsMachine from "./save-file-to-net-docs-machine"

const saveFileToNDMachine = createSaveToNetDocsMachine()

export default function useSaveFileToFolder({ fileUrl }) {
  let [state, trigger] = useMachine(saveFileToNDMachine)

  function onSaveFileToFolder({
    destinationId,
    destinationType,
    destinationName,
  }) {
    trigger({
      type: "START",
      fileUrl,
      destinationId,
      destinationName,
      destinationType,
    })
  }

  function onFolderNameSubmit(folderName) {
    trigger({ type: "SUBMIT", folderName })
  }

  function onRetryFileUpload(fileId) {
    trigger({ type: "RETRY", fileId })
  }

  return {
    onSaveFileToFolder,
    onFolderNameSubmit,
    onRetryFileUpload,
    state: selector(state),
  }
}

function selector(state) {
  return {
    value: state.value,
    context: state.context,
  }
}
