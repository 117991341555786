import * as React from "react"
import { Callout, Intent } from "@blueprintjs/core"

type StepErrorProps = {
  error?: Record<string, any>
  message?: string
  children?: React.ReactNode
}

const StepError = ({ error, message, children }: StepErrorProps) => (
  <Callout className="mt-4 rounded" intent={Intent.DANGER} icon="error">
    {message ? <p className="pb-3">{message}</p> : null}
    {children ? (
      <p className="pb-3">{children}</p>
    ) : (
      <>
        <p className="pb-3">
          Please refresh the page and try again. If the problem persists, please
          reach out to <i>support@simplyagree.com</i> and copy the information
          below.
        </p>
        <div className="overflow-auto" style={{ maxHeight: "150px" }}>
          {error && error.message ? (
            <>
              <b>{error.message}</b>
              <pre>{error.stack}</pre>
            </>
          ) : (
            <pre>{JSON.stringify(error, null, 2)}</pre>
          )}
        </div>
      </>
    )}
  </Callout>
)

export default StepError
