export default class ConcurrentRunner<T> {
  items: T[]
  numConcurrency: number
  results: any[]
  running: number
  resolve: any
  done: boolean

  constructor(items: T[], { concurrency = 1 } = {}) {
    this.items = [...items]
    this.numConcurrency = concurrency
    this.results = []
    this.running = 0
    this.done = false
  }

  run(fn: (i: T) => Promise<any>) {
    return new Promise((resolve) => {
      this.resolve = resolve
      for (let i = 0; i < this.numConcurrency; i++) {
        this._run(fn)
      }
    })
  }

  _run(fn: (i: T) => Promise<any>) {
    if (this.done) {
      if (this.running > 0) {
        // If there are still running tasks, wait for them to finish
        return
      }

      this.resolve(this.results)
      return
    }

    let item = this.items.shift()
    if (item) {
      this.running++
      fn(item)
        .then((value) => {
          const result = { status: "fulfilled", value }
          this.results.push(result)
          this.running--
          this._run(fn)
        })
        .catch((error) => {
          const result = { status: "rejected", reason: error }
          this.results.push(result)
          this.running--
          this._run(fn)
        })
    } else {
      this.done = true
      this._run(fn)
    }
  }
}
