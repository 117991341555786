export const ICONS = {
  rightArrow:
    "M19.5717345,7.56602427 L12.4339757,0.428265525 C12.1770164,0.157030692 11.8201285,0 11.420414,0 C10.6352605,0 9.99286224,0.642398287 9.99286224,1.42755175 C9.99286224,1.82726624 10.1498929,2.18415418 10.4068522,2.44111349 L15.1034975,7.13775874 L1.42755175,7.13775874 C0.642398287,7.13775874 0,7.78015703 0,8.56531049 C0,9.35046395 0.642398287,9.99286224 1.42755175,9.99286224 L15.117773,9.99286224 L10.4211278,14.6895075 C10.1641685,14.9464668 10.0071378,15.3033547 10.0071378,15.7030692 C10.0071378,16.4882227 10.649536,17.130621 11.4346895,17.130621 C11.834404,17.130621 12.1912919,16.9735903 12.4482512,16.716631 L19.58601,9.57887223 C19.8429693,9.32191292 20,8.96502498 20,8.56531049 C20,8.165596 19.8286938,7.82298358 19.5717345,7.56602427 Z",
  errorCircle:
    "M10,0 C4.475,0 0,4.475 0,10 C0,15.525 4.475,20 10,20 C15.525,20 20,15.525 20,10 C20,4.475 15.525,0 10,0 Z M11.25,16.25 L8.75,16.25 L8.75,13.75 L11.25,13.75 L11.25,16.25 Z M11.25,12.5 L8.75,12.5 L8.75,3.75 L11.25,3.75 L11.25,12.5 Z",
  bell: "M9.625,22 C11.1375,22 12.375,20.7625 12.375,19.25 L6.875,19.25 C6.875,20.7625 8.1125,22 9.625,22 L9.625,22 Z M17.875,15.125 C17.11875,15.125 16.5,14.50625 16.5,13.75 L16.5,8.25 C16.5,4.90875 14.12125,2.13125 10.9725,1.5125 C10.9725,1.47125 11,1.43 11,1.375 C11,0.61875 10.38125,0 9.625,0 C8.86875,0 8.25,0.61875 8.25,1.375 C8.25,1.43 8.2775,1.4575 8.2775,1.5125 C5.12875,2.145 2.75,4.9225 2.75,8.25 L2.75,13.75 C2.75,14.50625 2.13125,15.125 1.375,15.125 C0.61875,15.125 0,15.74375 0,16.5 C0,17.25625 0.61875,17.875 1.375,17.875 L17.875,17.875 C18.63125,17.875 19.25,17.25625 19.25,16.5 C19.25,15.74375 18.63125,15.125 17.875,15.125 L17.875,15.125 Z",
  circlePlus:
    "M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 Z M15,9 L11,9 L11,5 C11,4.45 10.55,4 10,4 C9.45,4 9,4.45 9,5 L9,9 L5,9 C4.45,9 4,9.45 4,10 C4,10.55 4.45,11 5,11 L9,11 L9,15 C9,15.55 9.45,16 10,16 C10.55,16 11,15.55 11,15 L11,11 L15,11 C15.55,11 16,10.55 16,10 C16,9.45 15.55,9 15,9 Z",
  sparkles: (
    <>
      <polygon points="7 0 5 5 0 6.99807408 5 9 7 14 9 9 14 7.00528342 9 5"></polygon>
      <polygon points="15 10 13.5 13.4963055 10 14.9948544 13.5 16.4931388 15 20 16.5 16.4931388 20 15 16.5 13.4963055"></polygon>
    </>
  ),
}

export { default as Icon } from "./icon"
