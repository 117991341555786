import { assign, createMachine } from "xstate"

import { reduceIntoKeyByValue } from "helpers/array"

import downloadFile from "./download-file"
import createFolder from "./create-folder"
import { bulkUploadDocumentsToND } from "./upload-document-to-nd"
import unZipFolder from "./unzip-folder"

export default function createSaveToNetDocsMachine(
  {
    context = {},
    initial = "idle",
    services: {
      downloadFileService = downloadFile,
      createFolderService = createFolder,
      uploadDocumentService = bulkUploadDocumentsToND,
      unZipFolderService = unZipFolder,
    },
  } = { services: {} }
) {
  const saveFileToNDMachine = createMachine(
    {
      id: "saveFileToND",
      initial,
      predictableActionArguments: true,
      context: {
        destinationId: null,
        destinationName: null,
        destinationType: null,
        downloadError: null,
        downloadFile: null,
        downloadProgress: 0,
        fileUrl: null,
        finalFolderId: null,
        folderCreatedSuccessfully: false,
        folderNameError: null,
        unZippedFiles: null,
        unZippedFilesError: null,
        uploadError: null,
        ...context,
      },
      states: {
        idle: {
          on: {
            START: {
              target: "download",
              actions: assign((_, event) => ({
                destinationId: event.destinationId,
                destinationType: event.destinationType,
                destinationName: event.destinationName,
                fileUrl: event.fileUrl,
              })),
            },
          },
        },
        createFolder: {
          invoke: {
            src: (ctx) =>
              createFolderService(ctx.destinationId, ctx.folderName),
            onDone: {
              target: "chooseUploadDocumentType",
              actions: assign((_, evt) => ({
                finalFolderId: evt.data.id,
                folderCreatedSuccessfully: true,
                // clear out the folderNameError if a subsequent folder creation succeeds
                folderNameError: null,
              })),
            },
            onError: {
              target: "createFolderError",
              actions: assign((_, event) => ({ folderNameError: event.data })),
            },
          },
        },
        createFolderError: {
          // on folder error, allow user to choose a different folder name
          // by re-handling the submit button to go to the createFolder step
          on: {
            SUBMIT: {
              target: "createFolder",
              actions: assign((_, event) => ({ folderName: event.folderName })),
            },
          },
        },
        chooseFolderName: {
          on: {
            SUBMIT: {
              target: "createFolder",
              actions: assign((_, event) => ({ folderName: event.folderName })),
            },
          },
        },
        download: {
          invoke: {
            src: (_, event) => (trigger) => {
              return downloadFileService(event.fileUrl, (progress) =>
                trigger({ type: "PROGRESS", progress })
              )
            },
            onDone: {
              target: "chooseFolderName",
              actions: assign((_, event) => ({ downloadFile: event.data })),
            },
            onError: {
              target: "downloadError",
              actions: assign((_, event) => ({ downloadError: event.data })),
            },
          },
          on: {
            PROGRESS: {
              actions: assign({
                downloadProgress: (_, event) => event.progress,
              }),
            },
          },
        },
        downloadError: {},
        addSingleFileToUploads: {
          always: [
            {
              target: "uploadDocuments",
              actions: assign({
                unZippedFiles: (ctx) => ({
                  single: {
                    id: "single",
                    name: ctx.downloadFile.name,
                    blob: ctx.downloadFile,
                  },
                }),
              }),
            },
          ],
        },
        chooseUploadDocumentType: {
          always: [
            { target: "addSingleFileToUploads", cond: "isSingleFile" },
            { target: "unZipFolderContents" },
          ],
        },
        uploadDocuments: {
          invoke: {
            src: (ctx) => (trigger) =>
              uploadDocumentService(
                ctx.finalFolderId,
                Object.values(ctx.unZippedFiles).filter((file) => !file.status),
                (id, progress) => trigger({ type: "PROGRESS", id, progress })
              ),
            onDone: {
              target: "uploadReview",
              actions: assign({
                unZippedFiles: (ctx, evt) => ({
                  ...ctx.unZippedFiles,
                  ...reduceIntoKeyByValue(evt.data),
                }),
              }),
            },
            onError: {
              target: "uploadDocumentsError",
              actions: (_, evt) => assign({ uploadDocumentsError: evt.data }),
            },
          },
          on: {
            PROGRESS: {
              actions: assign({
                unZippedFiles: (ctx, event) => {
                  let file = ctx.unZippedFiles[event.id]

                  ctx.unZippedFiles[event.id] = {
                    ...file,
                    progress: event.progress,
                  }

                  return ctx.unZippedFiles
                },
              }),
            },
          },
        },
        uploadDocumentsError: {},
        unZipFolderContents: {
          invoke: {
            src: (ctx) => unZipFolderService(ctx.downloadFile),
            onDone: {
              target: "uploadDocuments",
              actions: assign({
                unZippedFiles: (_, evt) => evt.data,
              }),
            },
            onError: {
              target: "unZipFolderContentsError",
              actions: { unZippedFilesError: (_, evt) => evt.data },
            },
          },
        },
        unZipFolderContentsError: {},
        uploadReview: {
          always: [{ target: "success", cond: "allFilesUploaded" }],
          on: {
            RETRY: {
              actions: assign({
                unZippedFiles: (ctx, evt) => {
                  let file = ctx.unZippedFiles[evt.fileId]

                  ctx.unZippedFiles[evt.fileId] = {
                    ...file,
                    progress: 0,
                    status: undefined,
                  }

                  return ctx.unZippedFiles
                },
              }),
              target: "uploadDocuments",
            },
          },
        },
        success: {
          final: true,
        },
        error: {
          final: true,
        },
      },
    },
    {
      guards: {
        isSingleFile: (ctx) => !ctx.fileUrl.includes(".zip"),
        allFilesUploaded: (ctx) =>
          Object.values(ctx.unZippedFiles).every((f) => f.status === "success"),
      },
    }
  )

  return saveFileToNDMachine
}
