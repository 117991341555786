import {
  CONVERSION_FAILURE,
  ORG_REPORT_READY,
  CLOSING_BINDER_DOWNLOAD,
  ITEM_DOWNLOAD_READY,
  SIGNING_MATRIX_REPORT,
  SIGNING_STATUS_REPORT,
  TRANSACTION_DOWNLOAD_READY,
} from "./utils"
import FileDownloadDialog from "./FileDownloadDialog"

/**
 * Returns the appropriate action for a notification based on the event type.
 *
 * @param {string} eventType - The type of notification, can be one of the enums above.
 * @param {*} downloadUrls - An object of potential download locations. Requires keys `pdf_file` or
 * `zip_file` to be present.
 */

type NotificationActionProps = {
  downloadUrls?: Record<string, any>
  eventType?: string
}

export const NotificationAction = ({
  eventType,
  downloadUrls,
  ...rest
}: NotificationActionProps) => {
  if (eventType === CONVERSION_FAILURE) {
    return <p>Try Again</p>
  }

  if (eventType === ORG_REPORT_READY) {
    return (
      <FileDownloadDialog
        {...rest}
        key="orgReportDownload"
        skipDownloadSelection
        eventType={eventType}
        downloadUrls={downloadUrls}
        downloadHref={downloadUrls.xls_file}
      />
    )
  }

  if (eventType === CLOSING_BINDER_DOWNLOAD) {
    return (
      <FileDownloadDialog
        {...rest}
        key="closingBinderDownload"
        eventType={eventType}
        downloadUrls={downloadUrls}
      />
    )
  }

  if (eventType === ITEM_DOWNLOAD_READY) {
    return (
      <FileDownloadDialog
        {...rest}
        key="itemDownload"
        eventType={eventType}
        downloadUrls={downloadUrls}
        skipDownloadSelection
        downloadHref={downloadUrls.pdf_file}
      />
    )
  }

  if (
    eventType === SIGNING_STATUS_REPORT ||
    eventType === SIGNING_MATRIX_REPORT
  ) {
    return (
      <a
        rel="noopener noreferrer"
        href={downloadUrls?.pdf_file}
        target="_blank"
      >
        Download
      </a>
    )
  }

  if (eventType === TRANSACTION_DOWNLOAD_READY) {
    return (
      <FileDownloadDialog
        {...rest}
        key="transactionDownloadReady"
        skipDownloadSelection={true}
        eventType={eventType}
        downloadHref={downloadUrls.zip_file}
        downloadUrls={downloadUrls}
      />
    )
  }

  return null
}

export default NotificationAction
